import React from "react";
import styles from "../../Containers/styles.module.css";

const cockpit = (props) => {
  //
  //
  //
  const assignedTextClasses = [];
  let btnClass = styles.button;

  if (props.showPersons) {
    btnClass = styles.Red;
  }

  if (props.persons.length <= 2) {
    assignedTextClasses.push(styles.red);
  }
  if (props.persons.length <= 1) {
    assignedTextClasses.push(styles.bold);
  }

  return (
    <div className={styles.Styles}>
      <h1>{props.title}</h1>
      <p className={assignedTextClasses.join(" ")}>
        This is really working - Persons Array ({props.persons.length})
      </p>
      <button className={btnClass} onClick={props.clicked}>
        Toggle People Button
      </button>
    </div>
  );
};

export default cockpit;
