import React from "react";
import personCSS from "./personstyles.module.css";

const person = (props) => {
  console.log("[Person.js] Rendering Person...", props);
  return (
    <div className={personCSS.Person}>
      <p onClick={props.click}>
        I'm {props.name} and I'm {props.age} years old!
      </p>
      <p>{props.children}</p>
      <input type="text" onChange={props.changed} value={props.name} />
    </div>
  );
};

export default person;
