//import React, { Component } from "react";
import React, { Component, useState, useEffect, useCallback } from "react";

import styles from "./styles.module.css";
import stylesLesson from "./App-Lesson.css";
import Persons from "../Components/Persons/Persons";
import Cockpit from "../Components/Cockpit/Cockpit";

import MoviesList from "../Components/MoviesList";

class App extends Component {
  //
  //
  //
  constructor(props) {
    super(props);
    console.log("[App.js] Constructor App()");
    console.log("[App.js] Constructor App(): React Version: " + React.version);
  }

  state = {
    persons: [
      { id: "1", name: "Sean", age: 57 },
      { id: "2", name: "Nancy", age: 58 },
      { id: "3", name: "Natalie", age: 26 },
      { id: "4", name: "Collin", age: 25 },
      { id: "5", name: "Abbey", age: 23 },
      { id: "6", name: "Ben", age: 29 },
    ],
    showPersons: true,
  };

  //
  //
  //
  static getDerivedStateFromProps(props, state) {
    console.log("[App.js] getDerivedStateFromProps(): ", props);
    return state;
  }

  //
  //
  //
  componentDidMount() {
    console.log("[App.js] componentDidMount(): ");
  }

  //
  //
  //
  nameChangedHandler = (event, id) => {
    const personIndex = this.state.persons.findIndex((p) => {
      return p.id === id;
    });

    const NewPerson = { ...this.state.persons[personIndex] };
    NewPerson.name = event.target.value;

    const copyOfpeople = [...this.state.persons];
    copyOfpeople[personIndex] = NewPerson;
    this.setState({ persons: copyOfpeople });
  };

  //
  //
  //
  deletePersonHandler = (personIndex) => {
    const copyOfpeople = [...this.state.persons];
    copyOfpeople.splice(personIndex, 1);
    this.setState({ persons: copyOfpeople });
  };

  //
  //
  //
  togglePeopleHandler = () => {
    const doesShow = this.state.showPersons;
    this.setState({ showPersons: !doesShow });
  };

  //
  //
  //
  render() {
    console.log("[App.js] render()");
    let persons = null;

    if (this.state.showPersons) {
      persons = (
        <Persons
          persons={this.state.persons}
          clicked={this.deletePersonHandler}
          changed={this.nameChangedHandler}
        />
      );
    }

    //
    //
    //
    return (
      <div className={styles.Styles}>
        <Cockpit
          title={this.props.appTitle}
          showPersons={this.state.showPersons}
          persons={this.state.persons}
          clicked={this.togglePeopleHandler}
        />
        {persons}
        <h1>Deployed 12/22/2022 @9:30am PST</h1>
      </div>
    );
  }
}

//
//
//
function AppLesson() {
  const [movies, setMovies] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  console.log("[App.js] AppLesson()");

  // "https://swapi.dev/api/films/"

  const fetchMoviesHandler = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch("https://swapi.dev/api/films/");
      if (!response.ok) {
        throw new Error("Something went wrong!");
      }

      const data = await response.json();

      const transformedMovies = data.results.map((movieData) => {
        return {
          id: movieData.episode_id,
          title: movieData.title,
          openingText: movieData.opening_crawl,
          releaseDate: movieData.release_date,
        };
      });
      setMovies(transformedMovies);
    } catch (error) {
      setError(error.message);
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    fetchMoviesHandler();
  }, [fetchMoviesHandler]);

  let content = <p>Found no movies.</p>;

  if (movies.length > 0) {
    content = <MoviesList movies={movies} />;
  }

  if (error) {
    content = <p>{error}</p>;
  }

  if (isLoading) {
    content = <p>Loading...</p>;
  }

  return (
    <React.Fragment>
      <section>
        <button onClick={fetchMoviesHandler}>Fetch Movies</button>
      </section>
      <section>{content}</section>
    </React.Fragment>
  );
}

function AppLessonDB() {
  const [movies, setMovies] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  console.log("[App.js] AppLessonDB()");

  const fetchMoviesHandler = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch("http://localhost:3000/users");
      if (!response.ok) {
        throw new Error("Something went wrong!");
      }

      const data = await response.json();

      console.log("Log Entry: ", data);

    } catch (error) {
      setError(error.message);
    }
    setIsLoading(false);
  }, []);

    let content = <p>Found no movies.</p>;

    if (movies.length > 0) {
      content = <MoviesList movies={movies} />;
    }
  
  if (error) {
    content = <p>{error}</p>;
  }

  if (isLoading) {
    content = <p>Loading...</p>;
  }

  return (
    <React.Fragment>
      <section>
        <button onClick={fetchMoviesHandler}>Fetch Movies</button>
      </section>
      <section>{content}</section>
    </React.Fragment>
  );
}

export default App;
